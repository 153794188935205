import React, {useState, useEffect} from 'react';
import {
  Box, MenuItem, Menu, IconButton,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {clonePage, isInvalidSlug} from 'api-lofty';
import {generateSlugSample} from '../util/generateSuggestionSlug';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
  boxMainFields: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  boxRowContainers: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: '5px',
  },
  urlWithMultipleColumns: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'row',
  },
  urlSectionContainer: {
    display: 'flex',
    margin: '2px',
    flexDirection: 'column',
  },
  urlFragmentSection: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  urlFragmentLabel: {
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    color: 'rgba(196, 196, 196, 1)',
    fontSize: '15px',
    fontWeight: 500,
  },
  urlInputSection: {
    border: '1px solid rgba(233, 231, 234, 1)',
    borderRadius: '15px',
  },
}));

const ClonePage = ({
  open, onClose, token, onSuccess, onError, idProject, _id, authProtocol,
  pageData,
}) => {
  // const [is404Page, setIs404Page] = React.useState(false);
  const [urlLabel, setUrlLabel] = React.useState('');
  const [counts, setCounts] = React.useState(0);
  const [urlInfo, setUrlInfo] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasError, setHasError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [isEditingLabel, setIsEditingLabel] = React.useState(false);
  const [suggestionSlug, setSuggestionSlug] = React.useState('');
  const classes = useStyle();
  const [typeOfPage, setTypeOfPage] = React.useState('');
  const [hasActiveLog, setHasActiveLog] = React.useState(authProtocol);
  const [redirectTo, setRedirectTo] = React.useState('');
  const typeSelectPage = [
    {
      name: 'Personalizada',
      value: 'custom',
    },
    {
      name: 'Principal',
      value: 'main',
    },
    {
      name: 'Error 404',
      value: 'error404',
    },
  ];
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleItemSelection = (value) => {
    if (value === 'param') {
      const toAdd = {idNumber: counts, preceding: '/:', value: ''};
      const temp = urlInfo;
      temp.push(toAdd);
      setUrlInfo(temp);
      setCounts(counts + 1);
      setAnchorEl(null);
    }
    if (value === 'route') {
      const toAdd = {idNumber: counts, preceding: '/', value: ''};
      const temp = urlInfo;
      temp.push(toAdd);
      setUrlInfo(temp);
      setCounts(counts + 1);
      setAnchorEl(null);
    }
  };
  const updateValueData = (index) => (e) => {
    const tempArray = [...urlInfo];
    tempArray[index].value = e.target.value;
    setUrlInfo(tempArray);
  };

  const eliminateValueFrom = (location) => {
    setUrlInfo((urlInfo) => urlInfo.filter((index) => index.idNumber !== location));
  };

  function generateSuggestionSlug(value) {
    if (isEditingLabel) {
      const res = generateSlugSample(pageData, value);
      setSuggestionSlug(res);
      setIsEditingLabel(false);
    }
  }

  const verifyCase = (e) => {
    const newValue = e.target.value;
    setSuggestionSlug(e.target.value);
    if (isInvalidSlug(newValue)) {
      setErrorText('Solo letras minusculas y numeros son aceptados');
      setHasError(true);
    } else {
      setErrorText('');
      setHasError(false);
    }
  };

  function changeLayout(value) {
    setTypeOfPage(value);
    if (value === 'error404') {
      setUrlLabel('*');
    } else if (value === 'main') {
      setUrlLabel('/');
    } else {
      setUrlLabel('');
    }
  }

  function onCloseRefresh() {
    setSuggestionSlug('');
    setHasError(false);
    setErrorText('');
    onClose();
  }

  useEffect(() => {
    setSuggestionSlug('');
  }, [open]);

  return (
    <Dialog open={open} onClose={() => onCloseRefresh()} maxWidth="700px">
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Clonar Pagina
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onCloseRefresh(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" style={{padding: '10px', margin: '5px', width: '650px'}}>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              let urlConstruct = urlLabel;
              // console.log(urlInfo);
              if (urlInfo && urlInfo.length > 0) {
                urlInfo.forEach((index) => urlConstruct += `${index.preceding}${index.value}`);
              }
              let authRetVal = 'not required';
              if (e.target.authProtocol !== undefined) {
                authRetVal = e.target.authProtocol.value;
              }
              try {
                await clonePage({
                  authProtocol: authRetVal,
                  token,
                  idProject,
                  _id,
                  name: e.target.name.value,
                  label: e.target.label.value,
                  description: e.target.description.value,
                  url: urlConstruct,
                  redirectPage: redirectTo || '',
                });
                if (onSuccess) {
                  setUrlInfo([]);
                  onSuccess();
                }
              } catch (error) {
              // console.log(error);
                if (onError) {
                  onError(error);
                }
              }
            }}
          >
            <div className="mt-4">
              <TextField select fullWidth name="typePage" type="text" label="Tipo de pagina" variant="outlined" onChange={(e) => changeLayout(e.target.value)}>
                {
                  typeSelectPage?.map((index) => (
                    <MenuItem value={index.value} key={index.value}>{index.name}</MenuItem>
                  ))
                }
              </TextField>
            </div>
            <Box component="div" className={classes.boxMainFields}>
              <Box component="div" className={classes.boxRowContainers}>
                <div className="my-4">
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Etiqueta"
                    name="label"
                    inputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                    required
                    onFocus={() => setIsEditingLabel(true)}
                    onBlur={(e) => generateSuggestionSlug(e.target.value)}
                  />
                </div>
                <div className="mt-4">
                  <TextField
                    select
                    fullWidth
                    name="authProtocol"
                    type="text"
                    label="Autenticacion de Usuario"
                    variant="outlined"
                    defaultValue={authProtocol}
                    onChange={(e) => setHasActiveLog(e.target.value)}
                  >
                    <MenuItem value="active" key="active">
                      Activo
                    </MenuItem>
                    <MenuItem value="inactive" key="inactive">
                      Inactivo
                    </MenuItem>
                    <MenuItem value="not required" key="not required">
                      No requerido
                    </MenuItem>
                  </TextField>
                </div>
                <div className="my-4">
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    maxRows={4}
                    label="Descripcion"
                    name="description"
                    inputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
              </Box>
              <Box component="div" className={classes.boxRowContainers}>
                <div className="my-4">
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Slug"
                    name="name"
                    required
                    helperText={errorText}
                    onChange={verifyCase}
                    error={hasError}
                    value={suggestionSlug}
                    inputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                {hasActiveLog === 'active'
                  && (
                    <div className="mt-4">
                      <TextField select fullWidth name="redirectTo" type="text" label="Redireccionar a" variant="outlined" onChange={(e) => setRedirectTo(e.target.value)}>
                        {
                        pageData.map((index) => (
                          <MenuItem value={index._id} key={index._id}>
                            {index.label}
                          </MenuItem>
                        ))
                      }
                      </TextField>
                    </div>
                  )}
                <div className="mt-4">
                  {typeOfPage === 'custom' && (
                    <Box component="div" className={classes.urlWithMultipleColumns}>
                      <Box component="div" className={classes.urlSectionContainer}>
                        URL:
                      </Box>
                      <Box component="div" className={classes.urlSectionContainer}>
                        {urlInfo.map((index, key) => (
                          <Box component="div" className={classes.urlFragmentSection}>
                            <Box className={classes.urlFragmentLabel}>
                              {index.preceding}
                            </Box>
                            <TextField
                              fullWidth
                              type="text"
                              label="seccion de url"
                              name={`section${index.idNumber}`}
                              value={index.value}
                              variant="outlined"
                              onChange={updateValueData(key)}
                            />
                            <IconButton
                              onClick={() => eliminateValueFrom(index.idNumber)}
                              className={classes.iconButtonStyle}
                            >
                              <DeleteOutlineOutlinedIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                      <Box component="div" className={classes.urlSectionContainer}>
                        <IconButton
                          onClick={handleOpenMenu}
                          className={classes.iconButtonStyle}
                        >
                          <AddIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                        >
                          <MenuItem onClick={() => handleItemSelection('param')}>Parametro</MenuItem>
                          <MenuItem onClick={() => handleItemSelection('route')}>Ruta</MenuItem>
                        </Menu>
                      </Box>
                    </Box>
                  )}
                </div>
              </Box>
            </Box>
            <div className="text-center my-4">
              <Button type="submit" variant="contained" color="primary">
                Guardar
              </Button>
            </div>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ClonePage;
