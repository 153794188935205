/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {
  Box, MenuItem, Menu, IconButton,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {updatePage, isInvalidSlug} from 'api-lofty';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
  boxMainFields: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  boxRowContainers: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: '5px',
  },
  urlWithMultipleColumns: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'row',
  },
  urlSectionContainer: {
    display: 'flex',
    margin: '2px',
    flexDirection: 'column',
  },
  urlFragmentSection: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  urlFragmentLabel: {
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    color: 'rgba(196, 196, 196, 1)',
    fontSize: '15px',
    fontWeight: 500,
  },
  urlInputSection: {
    border: '1px solid rgba(233, 231, 234, 1)',
    borderRadius: '15px',
  },
}));

const UpdateInfoPage = ({
  open, onClose, pageData, token, onSuccess, onError, _id, name, description,
  url, label, authProtocol, redirectPageInfo,
}) => {
  const [counts, setCounts] = React.useState(0);
  const [urlInfo, setUrlInfo] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [flag, setFlag] = useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [typeOfPage, setTypeOfPage] = React.useState('');
  const [hasActiveLog, setHasActiveLog] = React.useState(authProtocol);
  const [redirectTo, setRedirectTo] = React.useState(redirectPageInfo);
  const typeSelectPage = [
    {
      name: 'Personalizada',
      value: 'custom',
    },
    {
      name: 'Principal',
      value: 'main',
    },
    {
      name: 'Error 404',
      value: 'error404',
    },
  ];
  const classes = useStyle();

  if ((url !== '*' || url !== '/') && open && flag) {
    const temp = [];
    const stringFirstTokenizer = url.split('/');
    let tempCounts = 0;
    if (stringFirstTokenizer.length > 1) {
      stringFirstTokenizer.forEach((index, key) => {
        if (index === '' && key === 1) {
          const toAdd = {idNumber: tempCounts, preceding: '/', value: ''};
          temp.push(toAdd);
          tempCounts += 1;
        }
        if (index !== '' && index.includes(':')) {
          const tempInfo = index.split(':')[1];
          const toAdd = {idNumber: tempCounts, preceding: '/:', value: tempInfo};
          temp.push(toAdd);
          tempCounts += 1;
        } else if (index !== '') {
          const toAdd = {idNumber: tempCounts, preceding: '/', value: index};
          temp.push(toAdd);
          tempCounts += 1;
        }
      });
      setCounts(tempCounts);
      setUrlInfo(temp);
    }
    setFlag(!flag);
  }
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleItemSelection = (value) => {
    if (value === 'param') {
      const toAdd = {idNumber: counts, preceding: '/:', value: ''};
      const temp = urlInfo;
      temp.push(toAdd);
      setUrlInfo(temp);
      setCounts(counts + 1);
      setAnchorEl(null);
    }
    if (value === 'route') {
      const toAdd = {idNumber: counts, preceding: '/', value: ''};
      const temp = urlInfo;
      temp.push(toAdd);
      setUrlInfo(temp);
      setCounts(counts + 1);
      setAnchorEl(null);
    }
  };
  const updateValueData = (index) => (e) => {
    const tempArray = [...urlInfo];
    tempArray[index].value = e.target.value;
    setUrlInfo(tempArray);
  };

  const eliminateValueFrom = (location) => {
    setUrlInfo((urlInfo) => urlInfo.filter((index) => index.idNumber !== location));
  };

  const verifyCase = (e) => {
    const newValue = e.target.value;
    if (isInvalidSlug(newValue)) {
      setErrorText('Solo letras minusculas y numeros son aceptados');
      setHasError(true);
    } else {
      setErrorText('');
      setHasError(false);
    }
  };

  useEffect(() => {
    if (url === '*') {
      setTypeOfPage('error404');
    } else if (url === '/') {
      setTypeOfPage('main');
    } else {
      setTypeOfPage('custom');
    }
  }, [open]);

  return (
    <Dialog maxWidth="700px" open={open} onClose={() => { if (onClose) { setFlag(!flag); onClose(); } }}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Editar Informacion de Pagina
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { setFlag(!flag); onClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            let urlConstruct = '';
            if (urlInfo && urlInfo.length > 0) {
              urlInfo.forEach((index) => urlConstruct += `${index.preceding}${index.value}`);
            }
            // eslint-disable-next-line no-useless-escape
            // const urlRegex = /(^[\*]{1}$)|(^[\/]{1}$)|(^[\/]{1}[:]{0,1}[a-zA-Z0-9~#@=_]{0,1}\b(([a-zA-Z0-9@:%_\+~#?&//=]+))$)/;
            let authRetVal = 'not required';
            if (e?.target?.authProtocol !== undefined) {
              authRetVal = e.target.authProtocol.value;
            }
            if (typeOfPage === 'main') {
              urlConstruct = '/';
            } else if (typeOfPage === 'error404') {
              urlConstruct = '*';
            }
            try {
              await updatePage({
                token,
                _id,
                name: e.target.name.value,
                description: e.target.description.value,
                url: urlConstruct,
                label: e.target.label.value,
                authProtocol: authRetVal,
                redirectPage: redirectTo || '',
              });
              if (onSuccess) {
                setUrlInfo([]);
                onSuccess();
                setFlag(!flag);
              }
            } catch (error) {
              if (onError) {
                onError(error);
              }
            }
          }}
        >
          <Box component="div" className={classes.boxMainFields}>
            <Box component="div" className={classes.boxRowContainers}>
              <div className="mt-4">
                <TextField select value={typeOfPage} fullWidth name="typePage" type="text" label="Tipo de pagina" variant="outlined" onChange={(e) => setTypeOfPage(e.target.value)}>
                  {
                    typeSelectPage?.map((index) => (
                      <MenuItem value={index.value} key={index.value}>{index.name}</MenuItem>
                    ))
                  }
                </TextField>
              </div>
              <div className="my-4">
                <TextField variant="outlined" fullWidth label="Etiqueta" name="label" defaultValue={label} required />
              </div>
              {authProtocol
              && (
                <div className="mt-4">
                  <TextField
                    select
                    fullWidth
                    name="authProtocol"
                    type="text"
                    label="Autenticacion de Usuario"
                    variant="outlined"
                    defaultValue={authProtocol}
                    onChange={(e) => setHasActiveLog(e.target.value)}
                  >
                    <MenuItem value="active" key="active">
                      Activo
                    </MenuItem>
                    <MenuItem value="inactive" key="inactive">
                      Inactivo
                    </MenuItem>
                    <MenuItem value="not required" key="not required">
                      No requerido
                    </MenuItem>
                  </TextField>
                </div>
              )}
              <div className="my-4">
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  maxRows={4}
                  label="Descripcion"
                  name="description"
                  defaultValue={description}
                />
              </div>
            </Box>
            <Box component="div" className={classes.boxRowContainers}>
              <div className="my-4">
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Slug"
                  name="name"
                  defaultValue={name}
                  helperText={errorText}
                  onChange={verifyCase}
                  error={hasError}
                  required
                />
              </div>
              {hasActiveLog === 'active'
                      && (
                        <div className="mt-4">
                          <TextField
                            select
                            fullWidth
                            name="redirectTo"
                            type="text"
                            label="Redireccionar a"
                            variant="outlined"
                            defaultValue={redirectPageInfo}
                            onChange={(e) => setRedirectTo(e.target.value)}
                            value={redirectTo}
                          >
                            {
                              pageData.map((index) => (
                                <MenuItem value={index._id} key={index._id}>
                                  {index.label}
                                </MenuItem>
                              ))
                            }
                          </TextField>
                        </div>
                      )}
              {typeOfPage === 'main' || typeOfPage === 'error404'
                ? (
                  <div className="my-4">
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="URL"
                      name="url"
                      defaultValue={typeOfPage === 'main' ? '/' : '*'}
                      required
                      inputProps={{
                        readOnly: (url === '*' || url === '/'),
                      }}
                    />
                  </div>
                ) : (
                  <Box component="div" className={classes.urlWithMultipleColumns}>
                    <Box component="div" className={classes.urlSectionContainer}>
                      URL:
                    </Box>
                    <Box component="div" className={classes.urlSectionContainer}>
                      {urlInfo.map((index, key) => (
                        <Box component="div" className={classes.urlFragmentSection}>
                          <Box className={classes.urlFragmentLabel}>
                            {index.preceding}
                          </Box>
                          <TextField
                            fullWidth
                            type="text"
                            label="seccion de url"
                            name={`section${index.idNumber}`}
                            value={index.value}
                            variant="outlined"
                            onChange={updateValueData(key)}
                          />
                          <IconButton
                            onClick={() => eliminateValueFrom(index.idNumber)}
                            className={classes.iconButtonStyle}
                          >
                            <DeleteOutlineOutlinedIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                    <Box component="div" className={classes.urlSectionContainer}>
                      <IconButton
                        onClick={handleOpenMenu}
                        className={classes.iconButtonStyle}
                      >
                        <AddIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                      >
                        <MenuItem onClick={() => handleItemSelection('param')}>Parametro</MenuItem>
                        <MenuItem onClick={() => handleItemSelection('route')}>Ruta</MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                )}
            </Box>
          </Box>
          <div className="text-center my-4">
            <Button type="submit" variant="contained" color="primary" className={classes.buttonStyle}>
              Guardar
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateInfoPage;
